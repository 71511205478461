<template>
	<!-- <router-link to="/home">跳转到home</router-link> -->
      <router-view :key="$route.fullPath"/>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

</style>
