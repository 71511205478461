export default {
	headernav: {
		music: 'MUSIC',
		hotmusic: 'HOT MUSIC',
		hitmusic: 'HIT MUSIC',
		ourshareing: 'OUR SHARING',
		iwanttoshare: 'I WANT TO SHARE'
	},

	HOTMUSIC: 'HOT MUSIC',
	More: 'More',
	HITMUSIC: 'HIT MUSIC',
	OURSHARING: 'OUR SHARING',
	Video: 'Video',
	Music: 'Music',
	Singer:'Singer',
	ALL:'ALL',
	SOFTWARE: 'Video tutorial',
	VIDEOS:'VIDEOS',
	search: 'Searching Music or Singer',
	searchtxt: 'Please enter your search Music or Singer',
	searchret1: 'Search for ',
	searchret2: ' and the results are as follows ',
	usershare: 'I WANT TO SHARE',
	MESSAGE: 'MESSAGE',
	Confirm: 'Confirm',
	entername: 'Please enter your name',
	enteremail: 'Please enter your email address to prepare for answering your question',
	enterfeedback: 'Please enter your feedback',
	submitsuccess: "Submitted successfully, please wait patiently for review",
	submitfailed: "Failed to submit, please try again later",
	level: 'level',
	date:'date',
	download:'Download',
	nodata:'No relevant information at the moment',

	feedback: {
		title: 'Message feedback',
		name: 'name',
		nametxt: 'Please enter your name',
		email: 'email',
		emailtxt: 'Please enter your email',
		messagecontent: 'message content',
		messagecontenttxt: 'Please enter your content',
		Cancel: 'Cancel',
		Confirm: 'Confirm',
	},
	topost: {
		videotxt: 'The video cannot exceed 20M, and the video duration must be no less than 1 second and no more than 10 hours. Most mainstream video formats are supported',
		Videocover: 'Video cover',
		covertxt: 'A good cover is good for recommendations, so use images with higher definition and avoid images with GIFs and lots of text',
		information:'Information',
		videoname:'Video name',
		introduction:'Introduction',
		introductiontxt:'Accurate description of the video content is conducive to getting more recommendations',
		agree:'I have read and agree:',
		protocol:'User Made Content upload protocol',
		Submit:'Submit',
		songfiles:'Upload song files',
		delete:'delete',
		songcover:'Song cover',
		songcovertxt:'Please upload no less than 500 x 500 pixels',
		songtitle:'Song title',
		singer:'Singer',
		writer:'Writer',
		composer:'Composer',
		language:'Language',
		musicalstyle:'Musical style',
		songtxt:'Please upload mp3/wma/wav files with a bit rate of at least 128Kbps and within 200M',
		entervideotxt:'Please upload the correct video format',
		entervideoexceedtxt:'The video size cannot exceed 20MB',
		uploadcomplete:'Upload complete',
		enterimagetxt:'Please upload the correct image format',
		enterimageexceed:'The image size cannot exceed 2MB',
		entersongtxt:'Please upload the correct song format',
		entersongexceed:'The song size cannot exceed 20MB',
		entercoverimagetxt:'Please upload your artwork cover image',
		entervideoname:'Please enter name',
		enterintroduction:'Please enter brief introduction',
		enteruploadfile:'Please upload your file',
		postsuccess:'Submitted successfully, please wait patiently for review',
		postfiled:'Failed to submit, please try again later',
		enteruploadsong:'Please upload your music',
		entersinger:'Please enter the name of the singer',
		enterwriter:'Please enter the name of the writer',
		entercomposer:'Please enter the name of the composer',
		enterstyle:'Please select a style',
		enterlang:'Please select language'

	},
	login:{
		login:'Please log in first',
		title:'Welcome to the Open music platform',
		slogan:'Sound for you, music moves my heart',
		enteremail:'Enter your phone number or email address',
		enterpassword:'Please enter password',
		read1:'Read and agree',
		read2:'User agreement',
		read3:'and',
		read4:'Privacy Policy',
		log:'Log in',
		registration:'registration',
		registrationtitle:'New user registration',
		registrationtip:'Registration is easy in two steps',
		enterusername:'Enter your phone number or email address',
		enterpassword:'Please enter password',
		enterpassword2:'Please enter your confirmation password',
		readprivacypolicy:'Please read the User Agreement and Privacy Policy',
		enterfullinformation:'Please enter full information',
		accountfail:'The account or password is incorrect',
		success:'Login successful, rebounding',
		passwordfail:'Two passwords do not match',
		registrationfail:'Registration failure',
		accountregistrationfail:'The current account has been registered, please login to use',
		registrationsuccess:'Register successfully, go to login'
	},
	comment:{
		comment:'Leave a comment',
		
		comments:'comments',
		commenttxt:'Tell me what you think',
		publish:'publish',
		nocomment:'No relevant information at the moment',
		txt:'Enter your opinion',
		success:'Successful publication',
		failed:'Publishing failed. Please try again later'
	},
	userinfo:{
		logout:'Log out',
		account:'Account number',
		mymusic:'My music',
		myvideo:'My video',
		mymessage:'My message',
		underreview:'Under review',
		pass:'Pass',
		fail:'Fail',
		processed:'processed',
		recoverytime:'Recovery time'
	}


}