import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
// Vue Router
// import VueRouter from 'vue-router'
// app.use(VueRouter)
// 把上面那个引入的VueRouter替换掉
import VueRouter from './router'
app.use(VueRouter)

import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'
app.use(Layui);


import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
app.use(ElementPlus)

import i18n from './lang/index'
app.use(i18n)

 
 
// 请求ip地址
// app.config.globalProperties.$requestSrc = 'http://localhost:4691';
app.config.globalProperties.$requestSrc = ''//服务器前缀 ！记得request文件中也要改

app.config.globalProperties.$errorImg = '/images/errorpic.png';
app.config.globalProperties.$errorImgHeaderImg = '/images/headerimg.jpg';

app.mount('#app')
