export default {
headernav:{
		music:'音乐大全',
		hotmusic:'热门歌曲',
		hitmusic:'歌曲推荐',
		ourshareing:'我们的分享',
		iwanttoshare:'我要分享'
	},
	HOTMUSIC: '热门歌曲',
	More: '查看更多',
	HITMUSIC: '歌曲推荐',
	OURSHARING: '我们的分享',
	Video: '视频',
	Music: '音乐',
	Singer:'歌手',
	ALL:'全部',
	
	SOFTWARE: '视频教程',
	VIDEOS:'视频列表',
	search: '搜索音乐，歌手',
	searchtxt: '请输入搜索的音乐，歌手名称',
	searchret1:'查找到 ',
	searchret2:' 有以下结果：',
	usershare: '我要分享',
	MESSAGE: '留言',
	Confirm: '确认',
	entername: '请输入您的姓名',
	enteremail: '请输入您的邮件地址，以便我们与你联系',
	enterfeedback: '请输入您的联系方式',
	submitsuccess: "提交成功，请耐心等待审核完成",
	submitfailed: "提交失败，请稍后重试",
	  level:'难度',
	  date:'日期',
	 
	  download:'下载',
	   nodata:'没有相关信息',
	feedback:{
		title:'留言反馈',
			  name:'姓名',
			  nametxt:'请输入您的姓名',
			  email:'邮箱',
			  emailtxt:'请输入您的邮箱',
			  messagecontent:'留言内容',
			  messagecontenttxt:'请输入留言信息',
			  Cancel:'取消',
			  Confirm:'确认',
	},
	topost:{
		videotxt:'视频长度不小于1秒，不超过10小时。支持大多数主流视频格式',
		videocover:'视频封面',
		covertxt:'好的封面有利于推荐，所以使用高清晰度的图片，避免使用gif和大量文字的图片',
		information:'详情',
		videoname:'视频标题',
			introduction:'介绍',
			introductiontxt:'对视频内容的准确描述有利于获得更多的推荐',
			agree:'已阅读并同意:',
			protocol:'用户自制内容上传协议',
			Submit:'提交',
			songfiles:'上传歌曲文件',
			delete:'删除',
			songcover:'歌曲封面',
			songcovertxt:'请上传不小于500 × 500像素的图片',
			songtitle:'歌名',
			singer:'歌手',
			writer:'作词',
			composer:'作曲',
			language:'语种',
			musicalstyle:'风格',
			songtxt:'请上传比特率不低于128Kbps、200M以内的mp3/wma/wav文件',
			entervideotxt:'请上传正确的视频格式',
			entervideoexceedtxt:'视频大小不能超过20MB',
			uploadcomplete:'上传完成',
			enterimagetxt:'请上传正确的图片格式',
			enterimageexceed:'图片大小不能超过2MB',
			entersongtxt:'请上传正确歌曲格式',
			entersongexceed:'歌曲大小不能超过20MB',
			entercoverimagetxt:'请上传您的作品封面图片',
			entervideoname:'请输入标题',
			enterintroduction:'请输入简介',
			enteruploadfile:'请上传您的文件',
			postsuccess:'提交成功，请耐心等待审核',
			postfiled:'提交失败，请稍后再试',
			enteruploadsong:'请上传你的音乐',
			entersinger:'请上传你的音乐',
			enterwriter:'请输入作者的姓名',
			entercomposer:'请输入作曲者的名字',
			enterstyle:'请选择一个风格',
			enterlang:'请选择语种'
	},
	login:{
		login:'请先登录',
		title:'欢迎来到开放音乐平台',
		slogan:'音乐为你，乐动我心',
		enteremail:'输入您的电话号码或电子邮件地址',
		enterpassword:'请输入密码',
		read1:'阅读并同意',
		read2:'用户协议',
		read3:'和',
		read4:'隐私政策',
		log:'登录',
		registration:'注册',
		registrationtitle:'新用户注册',
		registrationtip:'注册只需两步',
		enterusername:'输入您的电话号码或电子邮件地址',
		enterpassword:'请输入密码',
		enterpassword2:'请输入确认密码',
		readprivacypolicy:'请阅读用户协议和隐私政策',
		enterfullinformation:'请输入完整信息',
		accountfail:'账号或密码不正确',
		success:'登录成功',
		passwordfail:'两个密码不匹配',
		registrationfail:'注册失败',
		accountregistrationfail:'当前账户已注册，请登录使用',
		registrationsuccess:'注册成功，进入登录'
	},
	comment:{
		comment:'留言',
		comments:'评论',
		commenttxt:'告诉我你的想法',
		publish:'提交',
		nocomment:'目前没有相关消息',
		txt:'输入您的看法',
		success:'发表成功',
		failed:'发表失败，请稍后重试'
	},
	userinfo:{
		logout:'退出登录',
		account:'账号',
		mymusic:'音乐',
		myvideo:'视频',
		mymessage:'信息',
		underreview:'审核中...',
		pass:'审核通过',
		fail:'审核不通过',
		processed:'processed',
		recoverytime:'审核时间'
	}


}