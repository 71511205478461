// 该文件是专门用于创建整个应用的路由器
import { createRouter, createWebHashHistory } from "vue-router"
 
const routes = [
   {
    
       path: '/',
       component: () => import('../views/home.vue'),
	   meta: {
	         title: 'Music website',
	       }
   },
	{
	    name: 'home',
	    path: '/home',
	    component: () => import('../views/home.vue'),
	   meta: {
	         title: 'Music website',
	       }
	},
	{
	    name: 'login',
	    path: '/login',
	    component: () => import('../views/login.vue'),
	   meta: {
	         title: 'Log in',
	       }
	}
	/*
	{
	    name: 'hitsong',
	    path: '/hitsong',
	    component: () => import('../views/songlist.vue'),
	   meta: {
	         title: 'Musice list',
	       }
	},
	{
	    name: 'singerlist',
	    path: '/singerlist',
	    component: () => import('../views/singerlist.vue'),
	   meta: {
	         title: 'Musice list',
	       }
	},
	{
	    name: 'recsong',
	    path: '/recsong',
	    component: () => import('../views/songlist.vue'),
	   meta: {
	         title: 'Musice list',
	       }
	},
	{
	    name: 'detail',
	    path: '/detail',
	    component: () => import('../views/songdetail.vue'),
	   meta: {
	         title: 'Details',
	       }
	},
	{
	    name: 'toshare',
	    path: '/toshare',
	    component: () => import('../views/toshare.vue'),
	   meta: {
	         title: 'Share',
	       }
	},
	{
	    name: 'sharelist',
	    path: '/sharelist',
	    component: () => import('../views/sharelist.vue'),
	   meta: {
	         title: 'Share list',
	       }
	},
	{
	    name: 'userinfo',
	    path: '/userinfo',
	    component: () => import('../views/userinfo.vue'),
	   meta: {
	         title: 'User center',
	       }
	},
	{
		name:'sharedetail',
		path: '/sharedetail',
		component: () => import('../views/sharedetail.vue'),
	   meta: {
	         title: 'Share details',
	       }
	},
	{
		name:'songlist',
		path: '/songlist',
		component: () => import('../views/songlist.vue'),
	   meta: {
	         title: 'Musice list',
	       }
	},
	{
		name:'videodetail',
		path: '/videodetail',
		component: () => import('../views/videodetail.vue'),
	   meta: {
	         title: 'Video',
	       }
	},
	{
		name:'videolist',
		path: '/videolist',
		component: () => import('../views/videolist.vue'),
	   meta: {
	         title: 'Videolist',
	       }
	}*/
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
	// 在每次导航之前滚动到页面顶部
	window.scrollTo({
		top: 0,
		behavior: 'smooth' // 可选的，使滚动平滑进行
	});
	if (to.meta.title) { // 判断是否有标题
	  document.title ="贝乐多玩具_" +to.meta.title;
	}
	next();
});

// 创建router实例对象并暴露
export default router
